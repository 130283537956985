<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadExperts(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showExpertDialog(null)">添加</el-button>
                    <el-button @click="importExpertsDialogVisible=true">导入</el-button>
                </div>
            </div>
            <el-table :data="experts.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="身份证号" sortable />
                <el-table-column prop="educationName" label="学历" sortable />
                <el-table-column prop="highestTitleLevelName" label="最高职称等级" sortable />
                <el-table-column prop="title" label="职称" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showExpertDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteExpert(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="experts.totalRecords"
                :current-page="expertsPagination.currentPage" :page-size="expertsPagination.pageSize" class="mt-3"
                @current-change="expertsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="expertDialogVisible" title="专家" :close-on-click-modal="false" width="800px">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="注册用户" class="form-item-required">
                            <el-input :value="expert.registeredUser.name" readonly placeholder="注册用户">
                                <template #append v-if="expert.id==undefined">
                                    <el-button @click="selectRegisteredUserDialogVisible=true">选择</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="学历" class="form-item-required">
                            <el-select v-model="expert.education" placeholder="学历">
                                <el-option
                                    v-for="education in $store.state.dataDefinitions.educations.filter(p=>p.key>4)"
                                    :key="education.key" :value="education.key" :label="education.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="最高职称等级" class="form-item-required">
                            <el-select v-model="expert.highestTitleLevel" placeholder="最高职称等级">
                                <el-option v-for="titleLevel in $store.state.dataDefinitions.titleLevels"
                                    :key="titleLevel.key" :value="titleLevel.key" :label="titleLevel.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称" class="form-item-required">
                            <el-input v-model="expert.title" placeholder="职称" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="擅长作物" class="form-item-required">
                    <el-select v-model="expert.goodAtCropIds" multiple placeholder="擅长作物">
                        <el-option v-for="crop in crops" :key="crop.id" :value="crop.id" :label="crop.name" />
                    </el-select>
                </el-form-item>
                <el-form-item label="职业技能" class="form-item-required">
                    <el-select v-model="expert.professionalSkillIds" multiple placeholder="职业技能">
                        <el-option v-for="professionalSkill in professionalSkills" :key="professionalSkill.id"
                            :value="professionalSkill.id" :label="professionalSkill.name" />
                    </el-select>
                </el-form-item>

                <div class="mt-3">
                    <div>附件</div>
                    <div class="mt-2">
                        <picture-uploader v-model="expert.attachments" />
                    </div>
                </div>

                <el-form-item label="备注" class="mt-2">
                    <el-input v-model="expert.remark" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveExpert">确定</el-button>
                <el-button @click="expertDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="false"
            @select="selectRegisteredUser" />

        <import-dialog v-model="importExpertsDialogVisible" action="/api/Expert/ImportExperts"
            template="/templates/专家导入模板.xls" :data="{companyId:$store.state.companyId}" @success="loadExperts" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                crops: [],
                professionalSkills: [],
                key: null,
                experts: {},
                expertsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                expert: { registeredUser: {} },
                expertDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
                importExpertsDialogVisible: false,
            };
        },
        methods: {
            async loadCrops() {
                let response = await this.$axios.get('/api/Crop/GetCrops');
                this.crops = response.data;
            },
            async loadProfessionalSkills() {
                let response = await this.$axios.get('/api/ProfessionalSkill/GetProfessionalSkills');
                this.professionalSkills = response.data;
            },
            async loadExperts(resetPage = false) {
                if (resetPage) {
                    this.expertsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Expert/GetExperts', {
                    params: {
                        status: 3,
                        key: this.key,
                        pageIndex: this.expertsPagination.currentPage - 1,
                        pageSize: this.expertsPagination.pageSize,
                    }
                });
                this.experts = response.data;
            },
            expertsPaginationCurrentChange(page) {
                this.expertsPagination.currentPage = page;
                this.loadExperts();
            },
            showExpertDialog(expert) {
                if (expert) {
                    this.expert = JSON.parse(JSON.stringify(expert));
                }
                else {
                    this.expert = {
                        registeredUser: {},
                        attachments: [],
                    };
                }
                this.expertDialogVisible = true;
            },
            selectRegisteredUser(user) {
                this.expert.registeredUser.id = user.id;
                this.expert.registeredUser.name = user.name;
            },
            async saveExpert() {
                if (this.expert.id == undefined) {
                    await this.$axios.post('/api/Expert/AddExpert', {
                        registeredUserId: this.expert.registeredUser.id,
                        education: this.expert.education,
                        highestTitleLevel: this.expert.highestTitleLevel,
                        title: this.expert.title,
                        goodAtCropIds: this.expert.goodAtCropIds,
                        professionalSkillIds: this.expert.professionalSkillIds,
                        attachments: this.expert.attachments,
                        remark: this.expert.remark,
                    });
                    this.expertDialogVisible = false;
                    this.loadExperts();
                }
                else {
                    await this.$axios.post('/api/Expert/UpdateExpert', {
                        id: this.expert.id,
                        education: this.expert.education,
                        highestTitleLevel: this.expert.highestTitleLevel,
                        title: this.expert.title,
                        goodAtCropIds: this.expert.goodAtCropIds,
                        professionalSkillIds: this.expert.professionalSkillIds,
                        attachments: this.expert.attachments,
                        remark: this.expert.remark,
                    });
                    this.expertDialogVisible = false;
                    this.loadExperts();
                }
            },
            async deleteExpert(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Expert/DeleteExpert', { id: id });
                this.loadExperts();
            },
        },
        created() {
            this.loadCrops();
            this.loadProfessionalSkills();
            this.loadExperts();
        },
    };
</script>